import { Button } from "../Button";
import { Container } from "../Container";

import "../../styles/hero.css";
import { siteSettings } from "../../settings";

export function Hero() {
  return (
    <Container className="pb-60 pt-20 text-center lg:pt-32">
      <h1 className="mx-auto max-w-4xl font-sans text-4xl font-semibold tracking-tight text-white sm:text-7xl hero_text ">
        <span> {""} Revolutionizing</span>
        <span> {""} your</span>
        <span> {""} digital</span>
        <span> {""} trading</span>
        <span> {""} experience </span>
      </h1>
      <p className="mx-auto mt-6 max-w-2xl text-[18px] tracking-tight text-[#CECFD1] hero_support">
        Elevate your investing game! Unlock the power of our AI-driven crypto
        trading bot and seasoned portfolio manager to streamline your trading
        process, save valuable time, and maximize your investment potential.
        24/7 support
      </p>
      <div className="mt-10 flex justify-center gap-x-6 hero_buttons">
        <Button href={`${siteSettings.appLink}/register`}>Get started</Button>
        <Button href={`${siteSettings.appLink}/login`} variant="outline">
          <span>Log in</span>
        </Button>
      </div>
    </Container>
  );
}

// import Image from 'next/image
// import logoLaravel from '@/images/logos/laravel.svg'
// import logoMirage from '@/images/logos/mirage.svg'
// import logoStatamic from '@/images/logos/statamic.svg'
// import logoStaticKit from '@/images/logos/statickit.svg'
// import logoTransistor from '@/images/logos/transistor.svg'
// import logoTuple from '@/images/logos/tuple.svg'
